import React from "react";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
            <img src="/img/logo.png" alt="Logo" style={{ height: "60px", marginRight: "10px" }} />
             {/* Adjust the height and margin as needed */}{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#page-top" className="page-scroll">
                Əsas
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                Haqqımızda
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                Xidmətlər
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll">
                Məhsullar
              </a>
            </li>
            {/* <li>
              <a href="#testimonials" className="page-scroll">
                Testimonials
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li> */}
            <li>
              <a href="#contact" className="page-scroll">
                Əlaqə
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
